@use 'sass:color';

$color1: #eae7dc;
$brown1: #CFAA75;
$brown2: #C08E45;
$brown3: rgb(161, 103, 74);
$purple: rgb(102, 0, 161);
$dark1: #3d3b3c;
$dark2: #323031;
$recipe-width: 360px;
$recipe-height: 540px;

body {
    background: $color1;
}

.cm-recipe-header {
    color: $brown3;
    font-weight: bold;
}

.cm-recipe-measurement {
    color: $brown3;
}

.cm-recipe-scaling {
    color: $purple;
}

.cm-keyword-to {
    color: $brown3;
}

.cm-recipe-name {
    //font-weight: bold;
    color: black;
}

.cm-error-token {
    border-bottom: 2px solid red !important;
}

.cm-shell-header {
    color: $dark1;
    font-weight: bold;
}

.cm-shell-type {
    background: $brown3;
    border-radius: 3px;
    padding: 1px 3px;
    color: white;
}

.cm-shell-type-white {
    background: none;
    border: 1px solid black;
    color: black;
    z-index: -1;
}

.cm-recipe-comment-header {
    font-weight: bold;
    color: rgb(148, 36, 83);
}

.cm-recipe-comment {
    color: rgb(148, 36, 83);
}

.CodeMirror-line {
    z-index: 0 !important;
}

.CodeMirror-selected {
    background: rgba(0, 0, 0, 0.1) !important;
}

.CodeMirror-focused .CodeMirror-selected {
    background: rgba(0.1, 0, 0, 0.15) !important;
}

.CodeMirror {
    flex-grow: 1;
    padding-left: 14px;
}

.cm-shell-type-dark {
    background: rgb(49, 28, 17);
}

.cm-shell-type-semi-dark {
    background: rgb(97, 50, 25);
}

.cm-shell-type-milk {
    background: $brown3;
}

.recipe-editor {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 40px 1fr;

    .top {
        grid-column: 1 / 3;
        grid-row: 1;
        display: flex;
        flex-direction: row;
        font-family: 'Bitter';
        background: rgb(207, 170, 117);
        border-radius: 3px;
        color: white;
        align-items: center;
        padding-left: 4px;

        a {
            background-color: rgba(207, 170, 117, 0);
            border-radius: 20px;
            transition: background-color 100ms;
            text-decoration: none;
            color: white;
            padding: 8px;

            &:hover {
                background-color: color.adjust(rgba(207, 170, 117, 0.4), $lightness: -20%);
            }
        }

        h1 {
            padding: 0px;
            margin: 0px;
            color: white;
            font-size: 18px;
            display: block;
            padding-left: 4px;
        }
    }

    .recipes {
        grid-column: 2;
        grid-row: 2;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .sidebar {
        grid-column: 1;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.moulds {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    user-select: none;
}

.mould-icon {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-content: center;
    margin-right: 4px;

    .mould-icon-rectangle {
        width: 60%;
        height: 60%;
        border: 1px solid white;
        align-self: center;
        text-align: center;
        line-height: 140%;
    }

    .mould-icon-circle,
    .mould-icon-oval {
        width: 70%;
        height: 70%;
        border-radius: 100px;
        border: 1px solid white;
        align-self: center;
        text-align: center;
        line-height: 160%;
    }
}

.mould {
    background: $brown1;
    color: white;
    font-family: "Bitter";
    padding: 4px;
    font-size: 10pt;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    align-items: center;
    padding-right: 6px;
    margin: 2px;
    margin-left: 0px;
    position: relative;
    min-width: 132px;

    .mould-names {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
    }

    .mould-usage-count {
        position: absolute;
        right: 0;
        top: 0;
        background: $brown2;
        padding: 3px;
        border-bottom-left-radius: 3px;
        width: 17px;
        text-align: center;
    }
}

.recipe-moulds {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-left: 14px;
    margin-right: 14px;
}

.recipe-moulds-dropzone {
    display: block;
    border-radius: 8px;
    border: 2px dashed $brown2;
    flex-grow: 1;
    height: 30px;
    padding-top: 10px;
    text-align: center;
}

.recipe:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: 3px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.34);
}

.recipe.draggable-mirror:before {
    z-index: 3;
    box-shadow: 0px 0px 8px 0px rgba(1, 0, 0, 0.34);
}

.draggable-mirror {
    z-index: 3;
}

.recipe {
    position: relative;
    display: block;
    width: $recipe-width;
    font-family: "Bitter";
    margin: 5px;
    height: $recipe-height;

    .recipe-inner {
        display: block;
        border-radius: 3px;
        background: white;
        overflow: hidden;
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    .recipe-top {
        padding-left: 14px;
        padding-top: 6px;
        padding-right: 14px;
        padding-bottom: 6px;
        background: $brown3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .recipe-name {
            background: none;
            color: white;
            margin: 0px;
            border: none;
            font-family: "Bitter";
            font-size: 14pt;
            flex-grow: 1;
        }

        .recipe-item-count {
            color: white;
            font-size: 10pt;

            &.large-count {
                font-size: 8pt;
            }
        }

        a {
            align-self: center;
            color: rgba(255, 255, 255, 0.5);
            font-size: 14pt;
            transition: 50ms color;
            cursor: pointer;

            &:hover {
                color: rgba(255, 255, 255, 1.0);
            }
        }
    }

    .recipe-contents {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .recipe-editor-holder {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }

    .recipe-bottom {
        display: flex;
        flex-direction: row;
        background: $brown3;
        padding: 8px;
        color: white;
        text-align: center;

        .recipe-total {
            flex: 1;
        }

        .recipe-leftover {
            flex: 1;
        }

        .box-top {
            font-size: 9pt;
        }
    }
}

.recipe-adder {
    display: flex;
    width: 320px;
    font-family: "Bitter";
    margin: 5px;
    border-radius: 8px;
    border: 3px solid $brown2;
    color: $brown2;
    // padding-top: 20px;
    // padding-bottom: 20px;
    height: $recipe-height;

    .recipe-adder-recipe-list {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;

        a {
            padding: 5px;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
            // border-bottom: 2px solid $brown2;

            &:hover {
                background: $dark1;
                color: $color1;
                border-radius: 5px;
            }
        }
    }
}

.recipe-adder-dashed {
    border: 3px dashed $brown2;
}

.recipe-adder-big-plus {
    align-items: center;
    justify-content: center;
    font-size: 60pt;
    flex-direction: column;

    a {
        display: block;
        font-size: 12pt;
    }
}

// DRAGDROP

.dragdrop-clone {
    z-index: 3;
    pointer-events: none;
}

// DRAGDROP

.btn-recipe {
    display: block;
    background: $brown3;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    color: white;
    margin: 5px;
    transition: 100ms background;
    cursor: pointer;
    user-select: none;
    margin-left: 14px;
    margin-right: 14px;

    &:hover {
        background: color.adjust($brown3, $lightness: -5%);
    }
}

.btn-disabled,
.btn-disabled:hover {
    background: color.adjust($brown3, $saturation: -100%);
}

.btn-sidebar {
    @extend .btn-recipe;
    margin: 2px;
}

.stats {
    font-family: 'Bitter';
    padding: 6px;
    background: $brown3;
    border-radius: 3px;
    margin: 2px;
    color: white;

    h4 {
        font-size: 9pt;
        font-family: "Bitter";
        padding: 0px;
        margin: 0px;
        margin-bottom: 2px;
    }

    >div:not(:last-child) {
        /* background: #935C41; */
        border-bottom: 2px solid white;
        padding-bottom: 4px;
    }

    h5 {
        margin-top: 5px;
        margin-left: 0px;
        text-transform: capitalize;
        margin-bottom: 0px;
    }

    span {
        font-size: 14px;
        margin-left: 8px;
    }
}

.recipes-plaintext {
    padding-top: 20px;
    padding-left: 40px;
    white-space: pre;

    h1,
    h3 {
        font-family: "Bitter";
    }

    @media print {
        h1 {
            display: none;
        }
    }
}

.recipe-plaintext {
    padding-top: 20px;
    break-after: always;
    break-inside: avoid;
}

.session-list {
    font-family: "Bitter";

    .session-list-item {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.34);
        display: block;
        border-radius: 3px;
        width: 600px;
        overflow: hidden;
        background: #EAE7DC;
        margin: 10px;

        a {
            text-decoration: none;
        }

        h2 {
            display: block;
            margin: 0px;
            padding: 0px;
            width: 100%;
            padding: 10px;
            background: $brown3;
            transition: 100ms background;

            &:hover {
                background: color.adjust($brown3, $lightness: -10%);
            }

            color: white;
            text-decoration: none;
        }

        ul {
            list-style: none;
        }

        li a {
            text-decoration: underline;
            color: $brown3;
        }
    }
}